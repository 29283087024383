import "swiper/swiper.scss";
import "aos/src/sass/aos.scss";
import "../sass/global.scss";

import { CountUp } from "countup.js";
import AOS from "aos";

import Swiper, { Autoplay, Navigation } from "swiper";

const frontpageStatsSliderContainer = document.querySelector('#frontpage-stats-slider-container');

if ( typeof frontpageStatsSliderContainer != 'undefined' && frontpageStatsSliderContainer != null ) {
	new Swiper(frontpageStatsSliderContainer, {
		modules: [Autoplay, Navigation],
		spaceBetween: 12,
		autoplay: {
			delay: 6000,
			pauseOnMouseEnter: false
		},
		speed: 1200,
		navigation: {
			prevEl: `#frontpage-stats-slider-prev`,
			nextEl: `#frontpage-stats-slider-next`
		},
		slidesPerView: 'auto',
	})
}

AOS.init();

(() => {
	const frontpageStatsNumbers = document.querySelectorAll('.frontpage-stats__grid__item__number');

	if ( frontpageStatsNumbers.length ) {
		frontpageStatsNumbers.forEach((number) => {
			new CountUp(number, parseInt(number.innerHTML), {
				enableScrollSpy: true,
				duration: 2,
				startVal: 1,
			});
		});
	}
})();

(() => {
	const navigationMobile = document.querySelector('.navigation-mobile');

	if ( typeof navigationMobile != 'undefined' && navigationMobile != null ) {
		const closeNavButton = navigationMobile.querySelector('.js-close-mobile-nav');
		const openNavButton = document.querySelector('.js-open-mobile-nav');

		closeNavButton.addEventListener('click', (e) => {
			e.preventDefault();
			navigationMobile.classList.remove('active');
		});

		openNavButton.addEventListener('click', (e) => {
			e.preventDefault();
			navigationMobile.classList.add('active');
		});
	}
})();

(() => {
	const iconHoverItems = document.querySelectorAll('img[data-icon-hover]');

	if ( iconHoverItems.length ) {
		iconHoverItems.forEach((item) => {
			const defaultIcon = item.src;
			const hoverIcon = item.dataset.iconHover;

			item.addEventListener('mouseenter', () => {
				item.src = hoverIcon;
			});

			item.addEventListener('mouseleave', () => {
				item.src = defaultIcon;
			});
		});
	}
})();

(() => {
	const programNavLinks = document.querySelectorAll('[data-program-tab]');
	const programColumns = document.querySelectorAll('[data-program-col]');
	const programGrid = document.querySelector('.program-plan__grid');

	if ( programColumns.length === 1 ) {
		programColumns.forEach((column) => {
			column.classList.add('hidden');
		});
		programNavLinks[0].style.display = 'none';
		programColumns[0].classList.remove('hidden');
		programNavLinks[1].classList.add('active');
		programGrid.classList.add('room-selected');
		document.querySelector('.program-plan__navigation-wrapper').style.display = "none";
		document.querySelector('.program-plan__grid__column__title').style.display = "none";
	} else {
		programNavLinks.forEach((link) => {
			link.addEventListener('click', (e) => {
				e.preventDefault();

				if ( !link.classList.contains('active') ) {
					programNavLinks.forEach((item) => item.classList.remove('active'));
					link.classList.add('active');

					const tabValue = link.dataset.programTab;

					const allRoomsOptions = 'all-rooms';

					if ( (tabValue != allRoomsOptions) ) {
						if ( !programGrid.classList.contains('room-selected') ) {
							programGrid.classList.add('room-selected');
						}

						programColumns.forEach((column) => {
							if ( column.classList.contains('hidden') ) {
								column.classList.remove('hidden');
							}

							const columnValue = column.dataset.programCol;

							if ( tabValue != columnValue ) {
								column.classList.add('hidden');
							}
						});
					} else {
						if ( programGrid.classList.contains('room-selected') ) {
							programGrid.classList.remove('room-selected');
						}

						programColumns.forEach((column) => {
							if ( column.classList.contains('hidden') ) {
								column.classList.remove('hidden');
							}
						});
					}
				}
			});
		});
	}

	if ( window.innerWidth < 768 ) {
		programColumns.forEach((column) => {
			column.classList.add('hidden');
		});

		programColumns[0].classList.remove('hidden');
		programNavLinks[1].classList.add('active');
	}
})();